const initState = {
  userData: {
    permissions: [],
    domains: [],
  },
};

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case "set_user_data":
      return { ...state, userData: action.payload };
    case "set_active_role_id":
      return {
        ...state,
        userData: { ...state.userData, active_role_id: action.payload },
      };
    default:
      return state;
  }
}
