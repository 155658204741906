import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "util/requests";
import styles from "../subjects.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import router from "routes";
import DomainTag from "../components/domain_tag";
import moment from "moment";

export default function MobileSubjectList(props) {
  const { study_id } = props;
  const [patientData, setPatientData] = useState([]);
  const permissions = useSelector((state) => state.user.userData.permissions);
  useEffect(() => {
    get(`/subjects/${study_id}`).then((ret) =>
      setPatientData(ret.data.slice(0, 10)),
    );
  }, []);

  return (
    <div className={styles.mobile_flex}>
      {permissions.includes("randomize_sidebar_icon") ? (
        <div className={styles.button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => router.navigate("/select_domain")}
          >
            Add Subject
          </LoadingButton>
        </div>
      ) : null}
      <div className={styles.recent_subjects}>Recent Subjects</div>
      <div className={styles.subject_list}>
        {patientData.map((patient) => (
          <div className={styles.subject_tile}>
            <div className={styles.mobile_subject_title}>{patient.id}</div>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              {patient.site_name} ({patient.site_id})
            </div>

            <div className={styles.domain_tile_wrapper}>
              Randomized:{" "}
              {(patient.randomization_domains.constructor === Array &&
                patient.randomization_domains.map((d) => (
                  <div className={styles.domain_tile_wrapper}>
                    <DomainTag text={d} />
                  </div>
                ))) || <div />}{" "}
            </div>
            <div className={styles.domain_tile_wrapper}>
              Consented:{" "}
              {(patient.consent_domains.constructor === Array &&
                patient.consent_domains.map((d) => (
                  <div className={styles.domain_tile_wrapper}>
                    <DomainTag text={d} />
                  </div>
                ))) || <div />}{" "}
            </div>
            {patient.last_randomized_on ? (
              <div style={{ marginTop: 10 }}>
                Last randomized:{" "}
                {moment(patient.last_randomized_on).format("yyyy-MMM-DD HH:mm")}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
