import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../user_detail.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get, post } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserRoleModal from "../../user_role_modal";
import UserDomainModal from "../../user_domain_modal";
import UserSiteModal from "../../user_site_modal";

import { useLocation } from "react-router-dom";
import { Checkbox, TextField } from "@mui/material";
import UserAccess from "./user_access";

export default function UserEditForm(props) {
  const { user, setEdit, setUser, siteList, roleList, domainList, regionList } =
    props;
  const [isSaving, setIsSaving] = useState(false);
  const [localUser, setLocalUser] = useState({
    roles: [],
    first_name: "",
    last_name: "",
    time_zone: null,
    phone: "",
    email: "",
    sites: [],
    regions: [],
  });
  const [roleSelectIsOpen, setRoleSelectIsOpen] = useState(false);
  const [domainSelectIsOpen, setDomainSelectIsOpen] = useState(false);
  const [siteSelectIsOpen, setSiteSelectIsOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);

  useEffect(() => {
    setLocalUser({ ...user });
    setSelectedRoleId(user.roles[0].id);
  }, []);

  const setValue = (key, value) => {
    setLocalUser({ ...localUser, [key]: value });
  };

  const updateUserRoleIds = (role_id_list) => {
    let updated_user = { ...user };
    updated_user.roles = roleList.filter((r) => role_id_list.includes(r.id));
    setUser(updated_user);
    setLocalUser(updated_user);
  };

  const updateUserDomainIds = (domain_id_list) => {
    let updated_user = { ...user };
    updated_user.domains = domainList
      .filter((d) => domain_id_list.includes(d.id))
      .map((d) => ({ ...d, role_id: selectedRoleId }));
    setUser(updated_user);
    setLocalUser(updated_user);
  };

  const updateUserSiteIds = ({ accessByRegion, sites, regions }) => {
    let updated_user = { ...user };
    updated_user.roles.find((r) => r.id === selectedRoleId).access_by_region =
      accessByRegion;
    if (accessByRegion) {
      updated_user.sites = [];
      updated_user.regions = regionList
        .filter((r) => regions.includes(r.id))
        .map((r) => ({ ...r, role_id: selectedRoleId }));
    } else {
      updated_user.sites = siteList
        .filter((s) => sites.includes(s.id))
        .map((s) => ({ ...s, role_id: selectedRoleId }));
    }

    let other_role_sites = user.sites.filter(
      (s) => s.role_id !== selectedRoleId,
    );
    updated_user.sites = updated_user.sites.concat(other_role_sites);

    let other_role_regions =
      user.regions?.filter((r) => r.role_id !== selectedRoleId) || [];
    updated_user.regions = updated_user.regions.concat(other_role_regions);

    console.log(updated_user);
    setUser(updated_user);
    setLocalUser(updated_user);
  };

  const role_string = user?.roles?.map((r) => r.name).join(", ") || "";

  const domain_string =
    user?.domains
      ?.filter((d) => d.role_id === selectedRoleId)
      .map((r) => r.name)
      .join(", ") || "";

  const entity_string = () => {
    let entities = [];
    if (user.roles.find((r) => r.id === selectedRoleId)?.access_by_region) {
      entities =
        (user?.regions &&
          regionList
            .filter((r) =>
              user.regions
                .filter((r) => r.role_id === selectedRoleId)
                .map((r) => r.id)
                .includes(r.id),
            )
            .map((s) => s.name)
            .join(", ")) ||
        "";
    } else {
      entities =
        (user?.sites &&
          siteList
            .filter((s) =>
              user.sites
                .filter((s) => s.role_id === selectedRoleId)
                .map((s) => s.id)
                .includes(s.id),
            )
            .map((s) => s.name)
            .join(", ")) ||
        "";
    }
    return entities;
  };

  return (
    <React.Fragment>
      <div className={styles.edit_form_wrapper}>
        <div className={styles.column_left}>
          <div className={styles.profile_row}>
            <TextField
              disabled
              label="Email"
              placeholder="text"
              value={localUser.email}
              onChange={(e) => setValue("email", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <TextField
              label="First Name"
              placeholder="text"
              value={localUser.first_name}
              onChange={(e) => setValue("first_name", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <TextField
              label="Last Name"
              placeholder="text"
              value={localUser.last_name}
              onChange={(e) => setValue("last_name", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <Autocomplete
              value={localUser.time_zone}
              options={moment.tz.names()}
              size="small"
              sx={{
                "& .MuiAutocomplete-root": { fontSize: "10px" },
                fontSize: 12,
                height: 30,
                padding: 0,
                alignSelf: "center",
                width: 300,
              }}
              renderInput={(params) => (
                <TextField {...params} label="Timezone" />
              )}
              onChange={(e, value) => {
                setValue("time_zone", value);
              }}
            />
          </div>
          <div className={styles.profile_row}>
            <TextField
              label="Phone"
              placeholder="text"
              value={localUser.phone}
              onChange={(e) => setValue("phone", e.target.value)}
              autoComplete="off"
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  width: "300px",
                },
              }}
            />
          </div>
        </div>
        <div className={styles.column_middle}>
          <UserAccess
            user={user}
            setUser={(user) => {
              setLocalUser(user);
              setUser(user);
            }}
            siteList={siteList}
            roleList={roleList}
            domainList={domainList}
            regionList={regionList}
          />
        </div>
      </div>
      <div className={styles.save_button_wrapper}>
        <LoadingButton
          variant="contained"
          loading={isSaving}
          onClick={() => {
            // setEdit(false);
            setIsSaving(true);
            put(`/user_details/${localUser.id}`, { ...localUser }).then(() => {
              setIsSaving(false);
              setEdit(false);
            });
          }}
          disabled={
            (localUser.sites?.length === 0 &&
              localUser.regions?.length === 0) ||
            localUser.roles.length === 0
          }
        >
          SAVE
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="inherit"
          sx={{ color: "#555" }}
          loading={false}
          onClick={() => {
            setEdit(false);
          }}
          style={{ marginLeft: 20 }}
        >
          CANCEL
        </LoadingButton>
      </div>
    </React.Fragment>
  );
}
