import { useEffect, useState, useRef } from "react";
import styles from "./qa_ui.module.css";
import { get, post } from "util/requests";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";
import SignaturePassword from "ui/crfs/signature_password";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import router from "routes";

export default function DropMenu(props) {
  const {
    form_name,
    crf_id,
    crf_data,
    crf_status,
    crf_sign_status,
    subject_id,
    updateCrfStatus,
    updateCrfSignStatus,
    showDeleteFormOption,
    deleteForm,
  } = props;
  const permissions = useSelector((state) => state.user.userData.permissions);
  const [showingInvestigatorSignModal, setShowingInvestigatorSignModal] =
    useState(false);
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const [showingDataEntryCompleteModal, setShowingDataEntryCompleteModal] =
    useState(false);
  const [
    showingRemoveDataEntryCompleteModal,
    setShowingRemoveDataEntryCompleteModal,
  ] = useState(false);
  const [showingRemoveDataMonitoredModal, setShowingRemoveDataMonitoredModal] =
    useState(false);
  const [showingDataMonitoredModal, setShowingDataMonitoredModal] =
    useState(false);
  const [modalText, setModalText] = useState("");
  const [password, setPassword] = useState("");
  const ref = useRef(null);

  const [showingMenu, setShowingMenu] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const dropMenuRef = useRef(null);

  const onKeyDown = (key) => {
    if (key.keyCode === 13) {
    }
  };

  const handleDocumentClick = (event) => {
    if (
      event.target &&
      dropMenuRef &&
      dropMenuRef.current &&
      !dropMenuRef.current.contains(event.target)
    ) {
      setShowingMenu(false);
    }
  };

  useEffect(() => {
    setPassword("");
  }, [crf_data && crf_data.crf_id]);

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  let status = null;

  const setDataEntryComplete = () => {
    post(`/crf_status/${subject_id}/${crf_id}`, {
      status: "data_complete",
    }).then(() => setShowingDataEntryCompleteModal(false));
    updateCrfStatus(crf_id, "data_complete");
  };

  const removeDataEntryComplete = () => {
    post(`/crf_status/${subject_id}/${crf_id}`, {
      status: "data",
      reason: modalText,
    }).then(() => setShowingRemoveDataEntryCompleteModal(false));
    updateCrfStatus(crf_id, "remove_data_complete");
    setModalText("");
  };

  const signCrf = (status, password) => {
    return new Promise((resolve) => {
      post(`/sign_crf/${subject_id}/${crf_id}`, { status, password })
        .then(() => {
          updateCrfSignStatus(crf_id, status);
          resolve({ success: true });
        })
        .catch(() => resolve({ success: false }));
    });
  };

  if (!crf_status && !crf_sign_status) {
    return null;
  }

  const menu_show_data_complete =
    crf_status === "data" && permissions.includes("set_data_to_complete");
  const menu_show_remove_data_complete =
    crf_status &&
    crf_status !== "data" &&
    permissions.includes("set_data_to_complete");
  const menu_show_monitored =
    permissions.includes("set_data_monitored_flag") &&
    !(crf_sign_status && crf_sign_status.includes("monitored"));
  const menu_show_remove_monitored =
    permissions.includes("set_data_monitored_flag") &&
    crf_sign_status &&
    crf_sign_status.includes("monitored");
  const menu_show_site_signoff =
    crf_status === "data_complete" &&
    !(crf_sign_status && crf_sign_status.includes("site_review")) &&
    permissions.includes("site_signoff");
  const menu_items_exist = () =>
    menu_show_data_complete ||
    menu_show_remove_data_complete ||
    menu_show_monitored ||
    menu_show_remove_monitored ||
    menu_show_site_signoff ||
    showDeleteFormOption;

  return (
    <div className={styles.qa_wrapper}>
      <div className={styles.tag_wrapper}>
        {crf_status === "data_complete" ? (
          <div className={styles.qa_tag}>
            <div>Data Complete</div>
            <div className={styles.check_mark}>
              <CheckIcon
                sx={{ color: "green", fontSize: 18, marginLeft: "6px" }}
              />
            </div>
          </div>
        ) : null}
        {crf_sign_status && crf_sign_status.includes("site_review") ? (
          <div className={styles.qa_tag}>
            Investigator Signed
            <div className={styles.check_mark}>
              <CheckIcon
                sx={{ color: "green", fontSize: 18, marginLeft: "6px" }}
              />
            </div>
          </div>
        ) : null}
        {crf_sign_status && crf_sign_status.includes("monitored") ? (
          <div className={styles.qa_tag}>
            Monitored{" "}
            <div className={styles.check_mark}>
              <CheckIcon
                sx={{ color: "green", fontSize: 18, marginLeft: "6px" }}
              />
            </div>
          </div>
        ) : null}
        <div>
          {menu_items_exist() ? (
            <div className={styles.ellipsis_button}>
              <MoreVertIcon onClick={() => setShowingMenu(true)} />
            </div>
          ) : null}
          {showingMenu ? (
            <div className={styles.hover_menu} ref={dropMenuRef}>
              {menu_show_data_complete ? (
                <div
                  className={styles.menu_button}
                  onClick={() => {
                    setShowingMenu(false);
                    setShowingDataEntryCompleteModal(true);
                  }}
                >
                  Set Data Complete Flag
                </div>
              ) : null}
              {menu_show_remove_data_complete ? (
                <div
                  className={styles.menu_button}
                  onClick={() => {
                    setShowingMenu(false);
                    setShowingRemoveDataEntryCompleteModal(true);
                  }}
                >
                  Remove Data Complete Flag
                </div>
              ) : null}
              {menu_show_monitored ? (
                <div
                  className={styles.menu_button}
                  onClick={() => {
                    setShowingMenu(false);
                    setShowingDataMonitoredModal(true);
                  }}
                >
                  Set Data Monitored Flag
                </div>
              ) : null}
              {false && menu_show_remove_monitored ? (
                <div
                  className={styles.menu_button}
                  onClick={() => {
                    setShowingMenu(false);
                    setShowingRemoveDataMonitoredModal(true);
                  }}
                >
                  Remove Data Monitored Flag
                </div>
              ) : null}
              {menu_show_site_signoff ? (
                <div
                  className={styles.menu_button}
                  onClick={() => {
                    setShowingMenu(false);
                    setShowingInvestigatorSignModal(true);
                  }}
                >
                  Set Investigator Signed Flag
                </div>
              ) : null}
              {showDeleteFormOption ? (
                <div
                  className={styles.menu_button}
                  onClick={() => {
                    setShowingMenu(false);
                    setShowingDeleteModal(true);
                  }}
                >
                  Delete Form
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      {false &&
      crf_status === "data" &&
      permissions.includes("set_data_to_complete") ? (
        <div>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() => setShowingDataEntryCompleteModal(true)}
            sx={{
              fontSize: 14,
              marginLeft: "6px",
              padding: "2px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            SET DATA TO COMPLETE
          </LoadingButton>
        </div>
      ) : null}

      {false &&
      permissions.includes("set_data_monitored_flag") &&
      !(crf_sign_status && crf_sign_status.includes("monitored")) ? (
        <div>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() => setShowingDataMonitoredModal(true)}
            sx={{
              fontSize: 14,
              marginLeft: "6px",
              padding: "2px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            SET DATA TO MONITORED
          </LoadingButton>
        </div>
      ) : null}

      {false &&
      crf_status === "data_complete" &&
      !(crf_sign_status && crf_sign_status.includes("site_review")) &&
      permissions.includes("site_signoff") ? (
        <div>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() => setShowingInvestigatorSignModal(true)}
            sx={{
              fontSize: 14,
              marginLeft: "6px",
              padding: "2px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            INVESTIGATOR SIGN
          </LoadingButton>
        </div>
      ) : null}

      <Dialog
        open={showingDeleteModal}
        onClose={() => setShowingDeleteModal(false)}
      >
        <div className={styles.modal_wrapper}>
          <div className={styles.modal_title}>
            Are you sure you want to delete this form?
          </div>

          <div className={styles.modal_button_wrapper}>
            <LoadingButton
              disabled={false}
              variant="contained"
              loading={false}
              onClick={() => {
                setSpinning(true);
                deleteForm().then(() => {
                  setShowingDeleteModal(false);
                  router.navigate(-1);
                });
              }}
            >
              YES
            </LoadingButton>
            <LoadingButton
              disabled={false}
              variant="outlined"
              loading={false}
              onClick={() => setShowingDeleteModal(false)}
            >
              NO
            </LoadingButton>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={showingInvestigatorSignModal}
        onClose={() => setShowingInvestigatorSignModal(false)}
      >
        <div className={styles.modal_wrapper}>
          <div className={styles.modal_title}>Electronic Signature Entry</div>
          <div className={styles.sign_for_label}>
            Enter your password to sign for:
          </div>
          <div className={styles.form_name_text}>{form_name}</div>
          <div className={styles.password_wrapper}>
            <SignaturePassword
              submit_text={"SUBMIT"}
              onSubmit={(password) => {
                //this returns to the signature form to set button state
                return new Promise((resolve) => {
                  signCrf("site_review", password).then(({ success }) => {
                    if (success) {
                      setShowingInvestigatorSignModal(false);
                    }
                    resolve(success);
                  });

                  // handleRandomize({
                  //   password,
                  //   modal_confirmation: confirmation,
                  // }).then(({ success }) => {
                  //   resolve(success);
                  // });
                });
              }}
              disabled={false}
              password={password}
              setPassword={setPassword}
            />
          </div>
          {/*} <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() =>
              signCrf("site_review").then(() => setShowingModal(false))
            }
          >
            SIGN
          </LoadingButton>*/}
        </div>
      </Dialog>
      <Dialog
        open={showingDataEntryCompleteModal}
        onClose={() => setShowingDataEntryCompleteModal(false)}
      >
        <div className={styles.modal_wrapper}>
          <div className={styles.modal_title}>Set Data Entry to Complete</div>
          <div className={styles.modal_body}>
            <div>This will flag the data as complete for this CRF.</div>
            <div>Is it complete?</div>
          </div>
          <div className={styles.modal_button_wrapper}>
            <LoadingButton
              disabled={false}
              variant="contained"
              loading={false}
              onClick={setDataEntryComplete}
            >
              YES
            </LoadingButton>
            <LoadingButton
              disabled={false}
              variant="outlined"
              loading={false}
              onClick={() => setShowingDataEntryCompleteModal(false)}
            >
              NO
            </LoadingButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={showingRemoveDataEntryCompleteModal}
        onClose={() => setShowingRemoveDataEntryCompleteModal(false)}
      >
        <div className={styles.modal_wrapper}>
          <div className={styles.modal_title}>
            Remove Data Complete Flag on this CRF
          </div>
          <div className={styles.modal_body}>
            <div className={styles.margin_bottom_10}>
              This will remove the Data Complete flag on this CRF.
            </div>
            <div className={styles.margin_bottom_10}>
              Please enter a reason why this is being done.
            </div>
            <TextField
              placeholder=""
              value={modalText}
              onChange={(e) => setModalText(e.target.value)}
              autoComplete="off"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  paddingLeft: "10px",
                  textAlign: "left",
                },
              }}
              fullWidth
              multiline
            />
          </div>
          <div className={styles.modal_button_wrapper}>
            <LoadingButton
              disabled={modalText.length < 4}
              variant="contained"
              loading={false}
              onClick={removeDataEntryComplete}
            >
              SUBMIT
            </LoadingButton>
            <LoadingButton
              disabled={false}
              variant="outlined"
              loading={false}
              onClick={() => {
                setShowingRemoveDataEntryCompleteModal(false);
                setModalText("");
              }}
            >
              CANCEL
            </LoadingButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={showingDataMonitoredModal}
        onClose={() => setShowingDataMonitoredModal(false)}
      >
        <div className={styles.modal_wrapper}>
          <div className={styles.modal_title}>Set Data Monitored Flag</div>
          <div className={styles.modal_body}>
            <div>This will flag the data as monitored for this CRF.</div>
            <div>Would you like to proceed?</div>
          </div>
          <div className={styles.modal_button_wrapper}>
            <LoadingButton
              disabled={false}
              variant="contained"
              loading={false}
              onClick={() => {
                signCrf("monitored").then(() =>
                  setShowingDataMonitoredModal(false),
                );
              }}
            >
              YES
            </LoadingButton>
            <LoadingButton
              disabled={false}
              variant="outlined"
              loading={false}
              onClick={() => setShowingDataMonitoredModal(false)}
            >
              NO
            </LoadingButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={showingRemoveDataMonitoredModal}
        onClose={() => setShowingRemoveDataMonitoredModal(false)}
      >
        <div className={styles.modal_wrapper}>
          <div className={styles.modal_title}>
            Remove Data Monitored Flag on this CRF
          </div>
          <div className={styles.modal_body}>
            <div className={styles.margin_bottom_10}>
              This will remove the Data Monitored flag on this CRF.
            </div>
            <div className={styles.margin_bottom_10}>
              Please enter a reason why this is being done.
            </div>
            <TextField
              placeholder=""
              value={modalText}
              onChange={(e) => setModalText(e.target.value)}
              autoComplete="off"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  paddingLeft: "10px",
                  textAlign: "left",
                },
              }}
              fullWidth
              multiline
            />
          </div>
          <div className={styles.modal_button_wrapper}>
            <LoadingButton
              disabled={modalText.length < 4}
              variant="contained"
              loading={false}
              onClick={() => {}}
            >
              SUBMIT
            </LoadingButton>
            <LoadingButton
              disabled={false}
              variant="outlined"
              loading={false}
              onClick={() => {
                setShowingRemoveDataMonitoredModal(false);
                setModalText("");
              }}
            >
              CANCEL
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
