import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../user_detail.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get, post } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserRoleModal from "../../user_role_modal";
import UserDomainModal from "../../user_domain_modal";
import UserSiteModal from "../../user_site_modal";

import { useLocation } from "react-router-dom";
import { Checkbox, TextField } from "@mui/material";

export default function UserAccess(props) {
  const { user, setEdit, setUser, siteList, roleList, domainList, regionList } =
    props;
  const logged_in_user_data = useSelector((state) => state.user.userData);

  const [isSaving, setIsSaving] = useState(false);
  const [roleSelectIsOpen, setRoleSelectIsOpen] = useState(false);
  const [domainSelectIsOpen, setDomainSelectIsOpen] = useState(false);
  const [siteSelectIsOpen, setSiteSelectIsOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);

  useEffect(() => {
    if (user?.roles?.length && !selectedRoleId) {
      setSelectedRoleId(user?.roles[0].id);
    }
  }, [user]);

  const updateUserRoleIds = (role_id_list) => {
    let updated_user = { ...user };

    role_id_list.forEach((role_id) => {
      if (!user.roles.find((r) => r.id === role_id)) {
        updated_user.roles.push(roleList.find((r) => r.id === role_id));
      }
    });

    updated_user.roles = updated_user.roles.filter((r) =>
      role_id_list.includes(r.id),
    );
    setUser(updated_user);
  };

  const updateUserDomainIds = (domain_id_list) => {
    let updated_user = { ...user };
    let other_role_domains = user.domains.filter(
      (d) => d.role_id !== selectedRoleId,
    );
    updated_user.domains = domainList
      .filter((d) => domain_id_list.includes(d.id))
      .map((d) => ({ ...d, role_id: selectedRoleId }));
    updated_user.domains = updated_user.domains.concat(other_role_domains);
    setUser(updated_user);
  };

  const updateUserSiteIds = ({ accessByRegion, sites, regions }) => {
    let updated_user = { ...user };
    updated_user.roles.find((r) => r.id === selectedRoleId).access_by_region =
      accessByRegion;
    if (accessByRegion) {
      updated_user.sites = [];
      updated_user.regions = regionList
        .filter((r) => regions.includes(r.id))
        .map((r) => ({ ...r, role_id: selectedRoleId }));
    } else {
      updated_user.sites = siteList
        .filter((s) => sites.includes(s.id))
        .map((s) => ({ ...s, role_id: selectedRoleId }));
    }

    let other_role_sites = user.sites.filter(
      (s) => s.role_id !== selectedRoleId,
    );
    updated_user.sites = updated_user.sites.concat(other_role_sites);

    let other_role_regions =
      user.regions?.filter((r) => r.role_id !== selectedRoleId) || [];
    updated_user.regions = updated_user.regions.concat(other_role_regions);

    console.log(updated_user);
    setUser(updated_user);
  };

  const role_string = user?.roles?.map((r) => r.name).join(", ") || "";

  const domain_string =
    user?.domains
      ?.filter((d) => d.role_id === selectedRoleId)
      .map((r) => r.name)
      .join(", ") || "";

  const entity_string = () => {
    let entities = [];
    if (user.roles?.find((r) => r.id === selectedRoleId)?.access_by_region) {
      entities =
        (user?.regions &&
          regionList
            .filter((r) =>
              user.regions
                .filter((r) => r.role_id === selectedRoleId)
                .map((r) => r.id)
                .includes(r.id),
            )
            .map((s) => s.name)
            .join(", ")) ||
        "";
    } else {
      entities =
        (user?.sites &&
          siteList
            .filter((s) =>
              user.sites
                .filter((s) => s.role_id === selectedRoleId)
                .map((s) => s.id)
                .includes(s.id),
            )
            .map((s) => s.name)
            .join(", ")) ||
        "";
    }
    return entities;
  };

  // console.log(moment.tz.names().filter((t) => t.includes("Mountain")));

  return (
    <React.Fragment>
      <div className={styles.edit_form_wrapper}>
        <div className={styles.column_right}>
          <div>
            <div className={styles.role_settings_title}>Roles</div>
            {user?.roles?.map((r) => (
              <div
                className={
                  r.id === selectedRoleId
                    ? styles.selected_row
                    : styles.unselected_row
                }
                onClick={() => setSelectedRoleId(r.id)}
              >
                {r.name}
              </div>
            ))}
          </div>

          <div className={styles.modify_roles_button}>
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() => {
                setRoleSelectIsOpen(true);
              }}
            >
              MODIFY ROLES
            </LoadingButton>
          </div>
          <hr />
        </div>

        <div className={styles.column}>
          {user?.roles?.length && selectedRoleId ? (
            <React.Fragment>
              <div className={styles.role_settings_title}>
                <strong>
                  {user?.roles?.find((r) => r.id === selectedRoleId)?.name} -
                  Settings
                </strong>
              </div>
              <div>
                <div className={styles.profile_row_title}>
                  {user.roles?.find((r) => r.id === selectedRoleId)
                    ?.access_by_region
                    ? "Regions"
                    : "Sites"}
                </div>
                <div className={styles.role_string_display}>
                  {entity_string()}
                </div>
              </div>

              <div className={styles.modify_roles_button}>
                <LoadingButton
                  variant="contained"
                  loading={false}
                  onClick={() => {
                    setSiteSelectIsOpen(true);
                  }}
                >
                  MODIFY ACCESS
                </LoadingButton>
              </div>
              <hr />

              <div>
                <div className={styles.profile_row_title}>Domains</div>
                <div className={styles.role_string_display}>
                  {domain_string}
                </div>
              </div>

              <div className={styles.modify_roles_button}>
                <LoadingButton
                  variant="contained"
                  loading={false}
                  onClick={() => {
                    setDomainSelectIsOpen(true);
                  }}
                >
                  MODIFY DOMAIN ACCESS
                </LoadingButton>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
      <div className={styles.edit_form_wrapper}>
        <UserRoleModal
          user={user}
          activeRoles={user.roles}
          roleList={roleList}
          roleSelectIsOpen={roleSelectIsOpen}
          setRoleSelectIsOpen={setRoleSelectIsOpen}
          updateParent={updateUserRoleIds}
        />
        <UserDomainModal
          user={user}
          activeDomains={
            user.domains.filter((s) => s.role_id === selectedRoleId) || []
          }
          domainList={domainList}
          roleSelectIsOpen={domainSelectIsOpen}
          setRoleSelectIsOpen={setDomainSelectIsOpen}
          updateParent={updateUserDomainIds}
          selectedRoleId={selectedRoleId}
        />
        <UserSiteModal
          user={user}
          activeSites={
            user.sites.filter((s) => s.role_id === selectedRoleId) || []
          }
          activeRegions={
            user.regions.filter((r) => r.role_id === selectedRoleId) || []
          }
          siteList={siteList}
          regionList={regionList}
          siteSelectIsOpen={siteSelectIsOpen}
          setSiteSelectIsOpen={setSiteSelectIsOpen}
          selectedRoleId={selectedRoleId}
          updateParent={updateUserSiteIds}
          currentLoggedInUserHasRegionAccess={
            logged_in_user_data.roles.find(
              (r) => r.id === logged_in_user_data.active_role_id,
            ).access_by_region
          }
          accessByRegion={
            user.roles?.find((r) => r.id === selectedRoleId)?.access_by_region
          }
          roleName={user.roles?.find((r) => r.id === selectedRoleId)?.name}
        />
      </div>
    </React.Fragment>
  );
}
