import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./user_detail.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get, post } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserRoleModal from "../user_role_modal";
import UserDomainModal from "../user_domain_modal";
import UserSiteModal from "../user_site_modal";
import UserViewForm from "./components/user_view_form";
import UserEditForm from "./components/user_edit_form";

import { useLocation } from "react-router-dom";
import { Checkbox, TextField } from "@mui/material";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className={styles.tab_panel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function UserDetails() {
  const study_id = useSelector((state) => state.study.active_study_id);
  const [isEdit, setEdit] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [siteList, setSiteList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const location = useLocation();
  const [user, setUser] = useState({
    email: "",
    first_name: "",
    last_name: "",
    time_zone: "",
    phone: "",
    roles: [],
    domains: [],
    sites: [],
    regions: [],
  });

  useEffect(() => {
    get(`/invite_user_sites_and_roles/${study_id}`).then((ret) => {
      const { data } = ret;
      setSiteList(data.sites);
      setRoleList(data.roles.map((r) => ({ name: r.name, id: r.id })));
      setDomainList(data.domains);
      setRegionList(data.regions);
    });
  }, []);

  const getUser = (id) => {
    get(`/user/${id}/${study_id}`).then((ret) => setUser(ret.data));
  };

  useEffect(() => {
    const id =
      location && location.pathname && location.pathname.split("/").slice(-1);
    if (id) {
      getUser(id);
    }
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading1}>
        {user.first_name} {user.last_name}
      </div>

      <div className={styles.created}>
        Created:{" "}
        {`${moment(user.created_on).format("YYYY-MM-DD HH:mm:ss")} 
          ${moment.tz(moment.tz.guess()).zoneAbbr()}`}
      </div>

      {!user.activated_on && !user.deactivated_on ? (
        <div className={styles.activated}>Not Yet Activated</div>
      ) : null}
      {user.activated_on && !user.deactivated_on ? (
        <div className={styles.activated}>
          Activated:{" "}
          {`${moment(user.activated_on).format("YYYY-MM-DD HH:mm:ss")} 
          ${moment.tz(moment.tz.guess()).zoneAbbr()}`}
        </div>
      ) : null}
      {user.activated_on && user.deactivated_on ? (
        <div className={styles.activated}>
          Deactivated:{" "}
          {`${moment(user.deactivated_on).format("YYYY-MM-DD HH:mm:ss")} ${moment.tz(moment.tz.guess()).zoneAbbr()}`}
        </div>
      ) : null}

      {isEdit ? (
        <UserEditForm
          user={user}
          setEdit={setEdit}
          setUser={setUser}
          siteList={siteList}
          roleList={roleList}
          domainList={domainList}
          study_id={study_id}
          regionList={regionList}
        />
      ) : (
        <UserViewForm
          user={user}
          setEdit={setEdit}
          siteList={siteList}
          study_id={study_id}
          getUser={getUser}
          regionList={regionList}
        />
      )}
    </div>
  );
}
