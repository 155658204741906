import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./user_profile.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get, post } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Checkbox } from "@mui/material";
import Authentication from "./components/authentication";
import SiteTable from "./components/site_table";
import UsersTable from "./components/users_table";
import UserDetail from "./components/user_detail";
import SiteDetail from "./components/site_detail";
import InviteUser from "./components/invite_user/etms";
import { useLocation } from "react-router-dom";
import router from "routes";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Form = (props) => {
  const { setEdit, user } = props;
  return (
    <div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Email:</div>
        <div>{user.email}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>First Name:</div>
        <div>{user.first_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Last Name:</div>
        <div>{user.last_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Timezone:</div>
        <div>{user.time_zone}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Phone:</div>
        <div>{user.phone}</div>
      </div>
      <LoadingButton
        variant="contained"
        loading={false}
        onClick={() => {
          setEdit(true);
        }}
      >
        EDIT
      </LoadingButton>
    </div>
  );
};

const EditForm = (props) => {
  const { user, setEdit, setUser } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [localUser, setLocalUser] = useState({
    time_zone: "",
    first_name: "",
    last_name: "",

    phone: "",
    email: "",
  });
  useEffect(() => {
    setLocalUser({ ...user });
  }, []);

  const setValue = (key, value) => {
    setLocalUser({ ...localUser, [key]: value });
  };

  return (
    <div>
      <div className={styles.profile_row}>
        <TextField
          disabled
          label="Email"
          placeholder="text"
          value={localUser.email}
          onChange={(e) => setValue("email", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="First Name"
          placeholder="text"
          value={localUser.first_name}
          onChange={(e) => setValue("first_name", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="Last Name"
          placeholder="text"
          value={localUser.last_name}
          onChange={(e) => setValue("last_name", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <Autocomplete
          value={localUser.time_zone}
          options={moment.tz.names()}
          size="small"
          sx={{
            "& .MuiAutocomplete-root": { fontSize: "10px" },
            fontSize: 12,
            height: 30,
            padding: 0,
            alignSelf: "center",
            width: 300,
          }}
          renderInput={(params) => <TextField {...params} label="Timezone" />}
          onChange={(e, value) => {
            setValue("time_zone", value);
          }}
        />
      </div>
      <div className={styles.profile_row}>
        <TextField
          label="Phone"
          placeholder="text"
          value={localUser.phone}
          onChange={(e) => setValue("phone", e.target.value)}
          autoComplete="off"
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              padding: "5px",
              width: "300px",
            },
          }}
        />
      </div>
      <LoadingButton
        variant="contained"
        loading={isSaving}
        onClick={() => {
          setUser(localUser);
          // setEdit(false);
          setIsSaving(true);
          put("/user_details", { ...localUser }).then(() => {
            setIsSaving(false);
            setEdit(false);
          });
        }}
      >
        SAVE
      </LoadingButton>
      <LoadingButton
        variant="contained"
        color="inherit"
        sx={{ color: "#555" }}
        loading={false}
        onClick={() => {
          setEdit(false);
        }}
        style={{ marginLeft: 20 }}
      >
        CANCEL
      </LoadingButton>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className={styles.tab_panel}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function AdminInterface() {
  const permissions = useSelector((state) => state.user.userData.permissions);
  const study_id = useSelector((state) => state.study.active_study_id);
  const [subTab, setSubTab] = useState("user_table");
  const [studyConfig, setStudyConfig] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(
    permissions.includes("user_admin") ? 0 : 1,
  );
  const location = useLocation();

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "admin",
    });
    get(`/study_config/${study_id}`).then((ret) => setStudyConfig(ret.data));
  }, [location.pathname]);

  useEffect(() => {
    if (location && /^\/admin$/i.test(location.pathname.trim())) {
      setSubTab("user_table");
    } else if (
      location &&
      /^\/admin\/users\/[0-9]{1,5}$/i.test(location.pathname.trim())
    ) {
      setActiveTabIndex(0);
      setSubTab("user_details");
    } else if (location && /^\/admin\/users$/i.test(location.pathname.trim())) {
      setActiveTabIndex(0);
      setSubTab("user_table");
    } else if (
      location &&
      /^\/admin\/user\/invite\/etms/i.test(location.pathname.trim())
    ) {
      setActiveTabIndex(0);
      setSubTab("user_invite_etms");
    } else if (
      location &&
      /^\/admin\/user\/invite\/corelab$/i.test(location.pathname.trim())
    ) {
      setActiveTabIndex(0);
      setSubTab("user_invite_corelab");
    } else if (location && /^\/admin\/sites$/i.test(location.pathname.trim())) {
      setActiveTabIndex(1);
      setSubTab("site_table");
    } else if (
      location &&
      /^\/admin\/sites\/[0-9]{1,5}$/i.test(location.pathname.trim())
    ) {
      setActiveTabIndex(1);
      setSubTab("site_details");
    } else if (
      location &&
      /^\/admin\/site\/add$/i.test(location.pathname.trim())
    ) {
      setActiveTabIndex(1);
      setSubTab("site_add");
    }
  }, [location]);

  if (!studyConfig) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading1}>Admin</div>
      <Tabs
        value={activeTabIndex}
        onChange={(e, newValue) => {
          if (newValue === 0) {
            router.navigate("/admin/users");
          } else if (newValue === 1) {
            router.navigate("/admin/sites");
          }
        }}
        aria-label="User Settings Tabs"
      >
        {permissions.includes("user_admin") ? (
          <Tab
            label="Users"
            {...a11yProps(0)}
            sx={{
              "&.MuiTab-root": {},
              "&.Mui-selected": {
                borderBottom: "0px",
              },
            }}
            onClick={() => {
              router.navigate("/admin/users");
            }}
          />
        ) : null}
        {permissions.includes("site_admin") ? (
          <Tab
            label="Sites"
            {...a11yProps(1)}
            sx={{
              "&.MuiTab-root": {},
              "&.Mui-selected": {},
            }}
            onClick={() => {
              router.navigate("/admin/sites");
            }}
          />
        ) : null}
      </Tabs>
      <TabPanel value={activeTabIndex} index={0}>
        {subTab === "user_details" ? <UserDetail /> : null}
        {subTab === "user_table" ? (
          <UsersTable studyConfig={studyConfig} />
        ) : null}
        {subTab === "user_invite_etms" ? (
          <InviteUser
            cancel={() => setSubTab("user_table")}
            application={"etms"}
          />
        ) : null}
        {subTab === "user_invite_corelab" ? (
          <InviteUser
            cancel={() => setSubTab("user_table")}
            application={"corelab"}
          />
        ) : null}
      </TabPanel>
      <TabPanel value={activeTabIndex} index={1}>
        {subTab === "site_table" ? (
          <SiteTable studyConfig={studyConfig} />
        ) : null}
        {subTab === "site_details" ? (
          <SiteDetail studyConfig={studyConfig} />
        ) : null}
        {subTab === "site_add" ? (
          <SiteDetail add studyConfig={studyConfig} />
        ) : null}
      </TabPanel>
    </div>
  );
}
