export const arrayHaveCommonItems = (arr1, arr2) => {
  if (
    arr1 &&
    arr1.constructor === Array &&
    arr2 &&
    arr2.constructor === Array
  ) {
    return arr1.some((item) => arr2.includes(item));
  } else return false;
};
