import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "util/requests";
import moment from "moment";
import router from "routes";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styles from "./users_table.module.css";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "first_name", headerName: "First Name", width: 200 },
  { field: "last_name", headerName: "Last Name", width: 150 },
  { field: "email", headerName: "Email", width: 280 },
  { field: "status", headerName: "Status", width: 90 },
  {
    field: "actvated",
    headerName: "Activated",
    width: 90,
    renderCell: ({ row }) =>
      row.activated_on && row.deactivated_on
        ? "no"
        : row.activated_on
          ? "yes"
          : "no",
  },
  { field: "phone", headerName: "Phone", width: 150 },
  {
    field: "last_login",
    headerName: `Last Login (${moment.tz(moment.tz.guess()).zoneAbbr()})`,
    width: 200,
    renderCell: ({ row }) => {
      return (
        (row.last_login &&
          moment(row.last_login).format("yyyy-MMM-DD HH:mm:ss")) ||
        ""
      );
    },
  },
  {
    field: "time_zone",
    headerName: "Time Zone",
    width: 150,
  },
  {
    field: "site",
    headerName: "Site",
    width: 150,
  },
];

const rows = [
  { id: "001", lastName: "Snow", firstName: "Jon", age: 35 },
  { id: "002", lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: "003", lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: "004", lastName: "Stark", firstName: "Arya", age: 16 },
  { id: "005", lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: "006", lastName: "Melisandre", firstName: null, age: 150 },
  { id: "007", lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: "008", lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: "009", lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export default function UsersTable(props) {
  const { studyConfig } = props;
  const [siteData, setSiteData] = useState([]);
  const [activeApplication, setActiveApplication] = useState(null);
  const [missingPermission, setMissingPermission] = useState(false);
  const study_id = useSelector((state) => state.study.active_study_id);

  let application_list = [];
  if (studyConfig.etms) {
    application_list.push({ label: "ETMS", value: "etms" });
  }
  if (studyConfig.corelab) {
    application_list.push({ label: "Corelab", value: "corelab" });
  }

  useEffect(() => {
    if (!studyConfig) return;
    setMissingPermission(false);
    get(`/users/${study_id}/${activeApplication || application_list[0].value}`)
      .then((ret) => setSiteData(ret.data))
      .catch((err) => {
        if (err?.response?.status === 423) {
          setMissingPermission(true);
        }
      });
  }, [activeApplication, studyConfig]);

  return (
    <div>
      <div className={styles.table_header}>
        {!missingPermission ? (
          <LoadingButton
            variant="contained"
            loading={false}
            style={{ marginBottom: 6 }}
            onClick={() =>
              router.navigate(
                `/admin/user/invite/${activeApplication || application_list[0].value}`,
              )
            }
          >
            <strong>INVITE USER</strong>
          </LoadingButton>
        ) : null}
        <div className={styles.application_dropdown}>
          <Select
            value={activeApplication || application_list[0].value}
            sx={{
              fontSize: 14,
              minWidth: 200,
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            onChange={(e) => {
              setActiveApplication(e.target.value);
            }}
          >
            {application_list.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {missingPermission ? (
        <div style={{ marginTop: 30 }}>
          You do not have the appropriate permissions to manage this.
        </div>
      ) : null}
      {!missingPermission ? (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={siteData}
            columns={columns}
            disableColumnSelector
            disableRowSelectionOnClick
            disableColumnMenu
            pageSize={5}
            rowsPerPageOptions={[5]}
            hideFooterSelectedRowCount
            pageSizeOptions={[]}
            columnHeaderHeight={34}
            rowHeight={34}
            sx={{
              boxShadow: 0,
              border: 1,
              borderColor: "#f0f0f0",
              "& .MuiDataGrid-cell:hover": {
                color: "green",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#e9f1fd",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
                color: "#333",
              },
            }}
            onRowClick={(row) => {
              router.navigate(`/admin/users/${row.id}`);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
