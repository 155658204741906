import React, { useState, useEffect } from "react";
import styles from "../user_profile.module.css";
import { Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { get, post } from "util/requests";
import { TextField } from "@mui/material";
import router from "routes";

export default function Authentication() {
  const [obj2fa, setOjb2fa] = useState({
    enable_2fa: false,
    google_2fa: false,
    sms_2fa: false,
    email_2fa: false,
    global_mfa_required: false,
  });
  const [googleCode, setGoogleCode] = useState("");
  const [checkingAuthCode, setCheckingAuthCode] = useState(false);
  const [googleChecked, setGoogleChecked] = useState(false);
  const [googleError, setGoogleError] = useState(false);

  useEffect(() => {
    get("/user_auth_2fa_details").then((ret) => {
      // console.log(ret.data);
      if (ret.data.google_2fa) {
        setGoogleChecked(true);
      }
      setOjb2fa(ret.data);
    });
  }, []);

  const hanndleGoogleChecked = (value) => {
    setGoogleChecked(value);
    if (value === false) {
      setValue("google_2fa", false);
    }
  };

  const handleGoogleAuthCodeEntry = () => {
    if (!googleCode || (googleCode && googleCode.length !== 6)) {
      setGoogleError("Code is the wrong length.");
      return;
    }

    setCheckingAuthCode(true);
    post("/validate_active_google2fa_enable_code", {
      qr_code: googleCode,
    })
      .then(() => {
        setValue("google_2fa", true);
        setCheckingAuthCode(false);
        setGoogleCode("");
      })
      .catch(() => {
        setGoogleError("Invalid code, please try again.");
        setCheckingAuthCode(false);
        setCheckingAuthCode(false);
        setGoogleCode("");
      });
  };

  const handleChangePassword = () => {
    router.navigate("/password_reset");
  };

  const setValue = (key, value) => {
    let newObj = { ...obj2fa };
    newObj[key] = value;
    newObj.google_2fa = newObj.google_2fa;
    post("/user_auth_2fa_details", newObj);
    setOjb2fa(newObj);
  };

  const onKeyDown2fa = (key) => {
    if (key.key === "Enter") {
      handleGoogleAuthCodeEntry();
    }
  };

  return (
    <div>
      <div className={styles.auth_wrapper}>
        <div className={styles.section_title}>2 Factor Authentication</div>
        <div className={styles.section_wrapper}>
          <div>
            <Checkbox
              checked={obj2fa.enable_2fa || obj2fa.global_mfa_required}
              className={""}
              onChange={(e) => setValue("enable_2fa", e.target.checked)}
              disabled={obj2fa.global_mfa_required}
            />{" "}
            Enable 2-factor Authentication
            {obj2fa.global_mfa_required ? (
              <div
                style={{
                  width: 500,
                  marginLeft: 50,
                  marginBottom: 20,
                  fontStyle: "italic",
                }}
              >
                2-factor Authentication is required for this system and cannot
                be disabled at the user level.
              </div>
            ) : null}
          </div>
          {obj2fa.enable_2fa || obj2fa.global_mfa_required ? (
            <div className={styles.options_2fa}>
              <div className={styles.options_2fa_title}>Options</div>
              <div>
                <Checkbox
                  checked={obj2fa.email_2fa || obj2fa.global_mfa_required}
                  className={""}
                  onChange={(e) => setValue("email_2fa", e.target.checked)}
                  disabled={obj2fa.global_mfa_required}
                />
                Email{" "}
                {obj2fa.global_mfa_required ? (
                  <span style={{ fontStyle: "italic" }}>
                    (required - default if no others are selected)
                  </span>
                ) : (
                  ""
                )}
              </div>
              {/*
            <div>
              <Checkbox
                checked={obj2fa.sms_2fa}
                className={""}
                onChange={(e) => setValue("sms_2fa", e.target.checked)}
              />
              SMS
            </div>*/}
              <div>
                <div>
                  <Checkbox
                    checked={googleChecked}
                    className={""}
                    onChange={(e) => hanndleGoogleChecked(e.target.checked)}
                  />
                  One-Time-Passcode App
                </div>
                {googleChecked && !obj2fa.google_2fa ? (
                  <div className={styles.qr_wrapper}>
                    <div>
                      {obj2fa.qrURL ? (
                        <img src={obj2fa.qrURL} width="160x" height="160px" />
                      ) : null}
                    </div>

                    <div>
                      <div className={styles.qr_description}>
                        Please scan this QR code with the a One-Time-Passcode
                        mobile app and enter the active 6-digit code in the box
                        below.
                      </div>
                      <div>
                        <TextField
                          label=""
                          placeholder=""
                          value={googleCode}
                          onChange={(e) => {
                            setGoogleCode(e.target.value);
                            setGoogleError(null);
                          }}
                          onKeyDown={onKeyDown2fa}
                          autoComplete="off"
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "5px",
                              width: "100px",
                            },
                          }}
                        />
                        <LoadingButton
                          variant="contained"
                          loading={checkingAuthCode}
                          onClick={handleGoogleAuthCodeEntry}
                          style={{ marginLeft: 20 }}
                        >
                          SUBMIT
                        </LoadingButton>
                        {googleError ? (
                          <div className={styles.error}>{googleError}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>

        <div className={styles.section_title}>Password</div>
        <div className={styles.section_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={handleChangePassword}
            style={{ marginLeft: 20 }}
          >
            CHANGE PASSWORD
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
