import React, { useState, useEffect } from "react";
import styles from "./add_another.module.css";
import { Checkbox } from "@mui/material";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import LoadingButton from "@mui/lab/LoadingButton";
import { cloneDeep } from "lodash";
import router from "routes";

export default function AddAnother(props) {
  const {
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    crfStatus,
    read_only,
    visit_index,
    field_array,
  } = props;

  const display_another = () => {
    let compared_field = field_array?.find(
      (f) => f.id === parseInt(field.display_logic?.field_id),
    );
    // if (field.display_logic.value) {
    if (
      compared_field?.value_obj?.value &&
      compared_field?.value_obj?.value !== ""
    ) {
      return true;
    } else {
      return false;
    }

    // }
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles.column}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() =>
              router.navigate(
                `/study/1/site/1/subjects/${subject_id}/edc/visit/${visit_index}/multi_entry/${crf_id}`,
              )
            }
            sx={{
              fontSize: 14,
              padding: "2px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            Go Back
          </LoadingButton>
        </div>
        <div className={styles.column}>
          {display_another() ? (
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() =>
                router.navigate(
                  `/study/1/site/1/subjects/${subject_id}/edc/visit/${visit_index}/multi_entry/${crf_id}/${crypto.randomUUID()}`,
                )
              }
              sx={{
                fontSize: 14,
                padding: "2px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              Add Another
            </LoadingButton>
          ) : null}
        </div>
      </div>
      <DividerLine />
    </div>
  );
}
