import React, { useState, useRef, useEffect } from "react";
import styles from "../decimal.module.css";
import { TextField } from "@mui/material";

import { cloneDeep } from "lodash";

let arrayz = [];

export default function DecimalUI(props) {
  const {
    field,
    title,
    options,
    sub_title,
    uval,
    onUpdate,
    crf_id,
    field_id,
    query,
    setQuery,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    enableQuery,
    show_checkmark,
    length,
    units = "",
    placeHolder,
    whole_length = 2,
    decimal_length = 1,
    value_position = "value",
    value_name_position = "value_name",
    crfStatus,
  } = props;

  const [localVal, setLocalVal] = useState(null);
  const [oldValue, setOldValue] = useState(uval);
  const [decimalPosition, setDecimalPosition] = useState(2);
  const field1Ref = useRef(null);
  const field2Ref = useRef(null);
  const field3Ref = useRef(null);
  const field4Ref = useRef(null);
  const field5Ref = useRef(null);
  const field6Ref = useRef(null);
  const field7Ref = useRef(null);
  const [refList, setRefList] = useState([
    field1Ref,
    field2Ref,
    field3Ref,
    field4Ref,
    field5Ref,
    field6Ref,
    field7Ref,
  ]);
  const [focusedArray, setFocusedArray] = useState([]);

  const parseValue = (position) => {
    // let parsed = localVal.split("").filter((t) => t !== ".");
    let parsed = localVal.split("");
    let dotIndex = parsed.findIndex((b) => b === ".");
    let frontFill = whole_length - dotIndex;
    parsed = [...Array(frontFill).fill(""), ...parsed];
    parsed = parsed.filter((d) => d !== ".");

    if (parsed[position] === " ") {
      return "";
    } else return parsed[position] || "";
  };

  const parseFullValue = (value) => {
    let parsed = value.split("");
    let dotIndex = parsed.findIndex((b) => b === ".");
    let frontFill = whole_length - dotIndex;
    if (frontFill < 0) frontFill = 0;
    parsed = [...Array(frontFill).fill(""), ...parsed];
    // parsed = parsed.filter((d) => d !== ".");
    return parsed;
  };

  useEffect(() => {
    let value = parseFullValue(
      (field.value_obj && field.value_obj[value_position]) || ".",
    );
    setLocalVal(value);
  }, [field.value_obj]);

  const setValue = (newValue) => {
    setLocalVal(newValue);
  };

  const regex_test = (value) => {
    if (
      RegExp(/^[0-9]{1,5}[.]{1}[0-9]{1,3}$/).test(value) ||
      RegExp(/^[0-9]{1,5}[.]$/).test(value)
    ) {
      // let split = value.split(".");
      return true;
      // if (
      //   split[0].length === whole_length &&
      //   split[1].length === decimal_length
      // ) {
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return false;
    }
  };

  const saveValueIfValid = (value) => {
    let obj = { ...field.value_obj };
    obj[value_position] = value;
    obj[value_name_position] = field[value_name_position];
    if (regex_test(obj[value_position])) {
      onUpdate(obj);
    }
  };

  // const onKeyDown = (key) => {
  //   if (key.keyCode === 13) {
  //     ref.current.blur();
  //   }
  // };
  const onFocus = (position) => {
    arrayz.push(position);
  };
  const onBlur = (position) => {
    setTimeout(() => {
      arrayz = arrayz.filter((p) => p !== position);
      if (arrayz.length === 0) {
        let value = [...localVal];
        value = guessAtValue(value);

        if (regex_test(value)) {
          saveValueIfValid(value);
        }
      }
    }, 100);
  };

  // useEffect(() => {
  //   let refs = [];
  //   for (let i = 0; i++; i < decimal_length) {
  //     refs.push(useRef(null));
  //     setRefList(refs);
  //   }
  // }, []);

  const onValueChange = (position, textValue, is_decimal = false) => {
    if (!/[0-9]/.test(textValue) && textValue !== "") return;
    let parsed = [...localVal];

    if (textValue) {
      parsed.splice(position, 1, textValue);
    } else {
      parsed.splice(position, 1, "");
    }

    setLocalVal(parsed);
    if (textValue && crfStatus !== "data_complete") {
      setTimeout(() => {
        if (is_decimal) {
          refList[position].current.focus();
        } else {
          refList[position + 1].current.focus();
        }
      }, 100);
    }
  };

  const onKeyDown = (key, position) => {
    if (key.keyCode === 13) {
      refList[position].current.blur();
    }
  };

  const guessAtValue = (value_array) => {
    value_array =
      value_array?.map((v) => {
        if (v === "") {
          v = 0;
        }
        return v;
      }) || [];
    if (!value_array.find((p) => p === ".")) {
      value_array.push(".");
      value_array.push("0");
    } else if (
      value_array.findIndex((p) => p === ".") ===
      value_array.length - 1
    ) {
      value_array.push("0");
    }
    value_array = value_array?.join("") || "";
    value_array = value_array?.replaceAll(/^[0]+/g, "");
    if (value_array[0] === ".") {
      value_array = `0${value_array}`;
    }
    return value_array;
  };

  if (!field.read_only) {
    return (
      <React.Fragment>
        <div className={styles.input_flex}>
          {Array.from(Array(whole_length).keys()).map((position) => (
            <div style={{ marginLeft: position === 0 ? 0 : 4 }} key={position}>
              <TextField
                inputRef={refList[position]}
                placeholder=""
                value={(localVal && localVal[position]) || ""}
                onChange={(e) => {
                  onValueChange(position, e.target.value);
                }}
                onKeyDown={(e) => {
                  onKeyDown(e, position);
                }}
                onFocus={() => {
                  onFocus(position);

                  // let update = [...focusedArray];
                  // update.push(position);
                  // setFocusedArray(update);
                }}
                onBlur={() => {
                  // arrayz = arrayz.filter((p) => p !== position);

                  onBlur(position);
                }}
                inputProps={{ maxLength: 1 }}
                autoComplete="off"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "24px",
                    fontSize: 14,
                  },
                }}
              />
            </div>
          ))}

          <div style={{ fontSize: 30 }}>.</div>
          {Array.from(Array(decimal_length).keys()).map((position) => (
            <div style={{ marginRight: 4 }}>
              <TextField
                inputRef={refList[position + whole_length]}
                placeholder=""
                value={
                  (localVal && localVal[whole_length + position + 1]) || ""
                }
                onKeyDown={(e) => {
                  onKeyDown(e, whole_length + position);
                }}
                onChange={(e) => {
                  onValueChange(
                    position + whole_length + 1,
                    e.target.value,
                    true,
                  );
                }}
                onFocus={() => {
                  onFocus(whole_length + position + 1);
                }}
                onBlur={() => {
                  // arrayz = arrayz.filter(
                  //   (p) => p !== whole_length + position + 1,
                  // );

                  onBlur(whole_length + position + 1);
                }}
                inputProps={{ maxLength: 1 }}
                autoComplete="off"
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "center",
                    width: "24px",
                    fontSize: 14,
                  },
                }}
              />
            </div>
          ))}
        </div>
        <div className={styles.guess_value}>{guessAtValue(localVal)}</div>
        {units ? (
          <div className={styles.input_description}>({units})</div>
        ) : null}
      </React.Fragment>
    );
  } else if (field.read_only) {
    return (
      <React.Fragment>
        <div>
          {field.value_obj[value_position]} {field.units || ""}
        </div>
      </React.Fragment>
    );
  }
}
