import React, { useEffect, useState } from "react";
import { get, post } from "util/requests";
import { useParams, useLocation } from "react-router-dom";
import styles from "./randomize_result.module.css";
import router from "routes";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";

export default function RandomizeResult() {
  const { randomization_id } = useParams();
  const [data, setData] = useState({ confirmation: false });
  const [intervention, setIntervention] = useState("");
  const [notAvailable, setNotAvailable] = useState(false);
  const location = useLocation();

  useEffect(() => {
    new Promise((resolve) => {
      get(`/subject/randomize/${randomization_id}`)
        .then((ret) => {
          const { data } = ret;
          setData(data);
          resolve(data);
        })
        .catch(() => setNotAvailable(true));
    }).then((data) => {
      post("/footprint", {
        path: location.pathname,
        action: "pageload",
        name: "randomization_result",
        subject_id: data.subjectID,
      });
    });
  }, []);

  // useEffect(() => {
  //   post("/footprint", {
  //     path: location.pathname,
  //     action: "pageload",
  //     name: "randomization_result",
  //   });
  // }, [location.pathname]);

  if (notAvailable) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.not_available}>
          <div>This randomization result is no longer available.</div>
          <div>
            Please refer to the allocation email you were sent, or contact
            actglobal@ucalgary.ca.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>
          Subject ID:{" "}
          <span className={styles.subject_id}>{data.subjectID}</span>
        </div>
        <div className={styles.title}>
          {data.domain_name} Randomization Result
        </div>
      </div>
      <div className={styles.first_message}>
        Randomization has been completed successfully. Please see details below.
      </div>

      <div className={styles.flex_center}>
        <div>
          <div className={styles.flex}>
            <div className={styles.label}>Subject ID:</div> {data.subjectID}
          </div>
          <div className={styles.flex}>
            <div className={styles.label}>Date and Time:</div>
            {moment(data.created_on).format("MMM DD, YYYY HH:mm")} MST
          </div>
          <div className={styles.flex}>
            <div>
              <div className={styles.label}>Treatment Allocation:</div>
            </div>{" "}
            <div className={styles.treatment}>{data.treatment_details}</div>
          </div>
          {data.dose ? (
            <div className={styles.flex}>
              <div className={styles.label}>Drug volume:</div> {data.dose}{" "}
              {data.units}
            </div>
          ) : null}
        </div>
      </div>

      {window.innerWidth >= 480 ? (
        <div className={styles.button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() =>
              router.navigate(`/study/1/site/1/subjects/${data.subjectID}/view`)
            }
          >
            Go to Visit Map
          </LoadingButton>
        </div>
      ) : (
        <div className={styles.button_wrapper}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => router.navigate(`/subjects`)}
          >
            Go to Subject List
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
