import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../user_detail.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment-timezone";
import Autocomplete from "@mui/material/Autocomplete";
import { put, get, post } from "util/requests";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UserRoleModal from "../../user_role_modal";
import UserDomainModal from "../../user_domain_modal";
import UserSiteModal from "../../user_site_modal";

import { useLocation } from "react-router-dom";
import { Checkbox, TextField } from "@mui/material";

export default function UserViewForm(props) {
  const { setEdit, user, siteList, study_id, getUser, regionList } = props;
  const [reinviteStatus, setReinviteStatus] = useState(null);
  const role_string =
    (user && user.role && user.role.map((r) => r.name).join(", ")) || "";
  const domain_string =
    (user && user.domains && user.domains.map((r) => r.name).join(", ")) || "";
  const sites_string =
    (user &&
      user.sites &&
      siteList
        .filter((s) => user.sites.map((s) => s.id).includes(s.id))
        .map((s) => s.name)
        .join(", ")) ||
    "";

  const resend_invite_email = () => {
    setReinviteStatus("sending");
    return new Promise((resolve) => {
      post("/resend_invite", {
        email: user.email,
        user_id: user.id,
        study_id,
      }).then(() => setReinviteStatus("complete"));
    });
  };
  return (
    <div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Email:</div>
        <div>{user.email}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>First Name:</div>
        <div>{user.first_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Last Name:</div>
        <div>{user.last_name}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Timezone:</div>
        <div>{user.time_zone}</div>
      </div>
      <div className={styles.profile_row}>
        <div className={styles.profile_row_title}>Phone:</div>
        <div>{user.phone}</div>
      </div>
      <div className={styles.profile_row_title}>Access by Role:</div>
      <div className={styles.role_access_window}>
        <div>
          {user.roles.map((r) => (
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>{r.name}</div>
              <div style={{ marginLeft: 20 }}>
                {!r.access_by_region ? (
                  <div>
                    <div
                      style={{ textDecoration: "underline", marginBottom: 4 }}
                    >
                      Sites
                    </div>
                    {user.sites
                      ?.filter((s) => s.role_id === r.id)
                      ?.map((s) => (
                        <div style={{ marginLeft: 20 }}>
                          {siteList.find((sl) => sl.id === s.id)?.name}{" "}
                          {siteList.find((sl) => sl.id === s.id)?.code}
                        </div>
                      ))}
                  </div>
                ) : null}
                {!!r.access_by_region ? (
                  <div>
                    <div
                      style={{ textDecoration: "underline", marginBottom: 4 }}
                    >
                      Regions
                    </div>
                    {user.regions
                      ?.filter((s) => s.role_id === r.id)
                      ?.map((s) => (
                        <div style={{ marginLeft: 20 }}>
                          {regionList.find((sl) => sl.id === s.id)?.name}{" "}
                          {regionList.find((sl) => sl.id === s.id)?.code}
                        </div>
                      ))}
                  </div>
                ) : null}
                <div>
                  <div style={{ textDecoration: "underline", marginBottom: 4 }}>
                    Domains
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    {user.domains
                      ?.filter((s) => s.role_id === r.id)
                      ?.map((s) => s?.name)
                      .join(", ")}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.profile_row}>
        <LoadingButton
          variant="contained"
          loading={false}
          onClick={() => {
            setEdit(true);
          }}
        >
          EDIT
        </LoadingButton>
      </div>

      {user.status === "invited" && reinviteStatus !== "complete" ? (
        <div className={styles.profile_row}>
          <LoadingButton
            variant="contained"
            loading={reinviteStatus === "sending"}
            onClick={() => {
              resend_invite_email();
            }}
          >
            Resend Invite Email
          </LoadingButton>
        </div>
      ) : null}
      {reinviteStatus === "complete"
        ? "This user has been resent the invite email."
        : null}
      {!user.activated_on || user.deactivated_on ? (
        <div className={styles.activation_button}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              post("/activate_user", { user_id: user.id, study_id }).then(() =>
                getUser(user.id),
              );
            }}
          >
            ACTIVATE USER
          </LoadingButton>
        </div>
      ) : null}
      {user.activated_on && !user.deactivated_on ? (
        <div className={styles.activation_button}>
          <LoadingButton
            variant="contained"
            loading={false}
            onClick={() => {
              post("/deactivate_user", { user_id: user.id, study_id }).then(
                () => getUser(user.id),
              );
            }}
          >
            DEACTIVATE USER
          </LoadingButton>
        </div>
      ) : null}
    </div>
  );
}
