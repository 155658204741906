import { get, post_raw, post } from "util/requests";

let is_pinged_server_for_login_status = false;

export const setToken = (token) => localStorage.setItem("token", token);

export const login = (email, password, _2fa_code = null, type = null) => {
  return new Promise((resolve, reject) => {
    return post_raw("/auth/signin", {
      email,
      password,
      _2fa_code,
      _2fa_type: type,
    })
      .then((res) => {
        if (
          res.data &&
          res.data.token_data &&
          res.data.token_data.access_token &&
          res.data.token_data.refresh_token
        ) {
          setToken(res.data.token_data.access_token);
          localStorage.setItem(
            "refresh_token",
            res.data.token_data.refresh_token,
          );
        }
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export function getToken() {
  try {
    const token = localStorage.getItem("token");
    return token;
  } catch (err) {
    clearToken();
    return null;
  }
}

export function getRefreshToken() {
  try {
    const token = localStorage.getItem("refresh_token");
    return token;
  } catch (err) {
    clearToken();
    return null;
  }
}

export function getUserId() {
  return 1;
}

export function checkHasActiveSignIn() {
  return new Promise((resolve, reject) => {
    const token = getToken();
    if (token) {
      if (!is_pinged_server_for_login_status) {
        get("/auth/check_auth_is_valid")
          .then((ret) => {
            if (ret.data && ret.data.user_data) {
              resolve({ user_data: ret.data.user_data });
            } else {
              resolve({});
            }
          })
          .catch((res) => {
            clearToken();
            reject();
          });
      } else {
        is_pinged_server_for_login_status = true;
        resolve();
      }
    } else {
      reject();
    }
  });
}

export function clearToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
}

export const logout = () => {
  post("/auth/logout");
  localStorage.removeItem("token");
  window.location = "/";
  window.onfocus = null;
};
