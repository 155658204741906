import { useEffect, useState, useRef } from "react";
import styles from "./modulebar.module.css";
import { logout } from "util/auth";
import { useLocation } from "react-router-dom";
import Diversity2SharpIcon from "@mui/icons-material/Diversity2Sharp";
import AdjustIcon from "@mui/icons-material/Adjust";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewListIcon from "@mui/icons-material/ViewList";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import MedicationIcon from "@mui/icons-material/Medication";
import AddIcon from "@mui/icons-material/Add";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PriorityHighIcon from "@mui/icons-material/PriorityHighRounded";
import { version } from "settings";
import router from "routes";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setActiveStudyId } from "redux/study/actions";
import { setActiveRoleId } from "redux/user/actions";
import { post } from "util/requests";
import { useResetTimer } from "timer";
import { arrayHaveCommonItems } from "util/helpers";

export default function ModuleBar(props) {
  const user_data = useSelector((state) => state.user.userData);
  const permissions =
    useSelector((state) => state.user.userData.permissions) || [];
  const study_id = useSelector((state) => state.study.active_study_id);
  const role_id = useSelector((state) => state.user.userData.active_role_id);
  const dispatch = useDispatch();

  const { showing_module_bar } = props;
  const location = useLocation();
  const path_string = location.pathname;
  const ref = useRef(null);
  const [activeTile, setActiveTile] = useState("subjects");
  const reset_logout_timer = useResetTimer();

  const handleClickOutsideModuleBar = (event) => {
    reset_logout_timer();
    if (
      ref &&
      ref.current &&
      event.target &&
      !ref.current.contains(event.target) &&
      !(
        props.waffle_ref &&
        props.waffle_ref.current &&
        props.waffle_ref.current.contains(event.target)
      )
    ) {
      props.close_module_bar();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModuleBar);
  }, []);
  useEffect(() => {
    if (path_string.includes("subject")) {
      setActiveTile("subjects");
    } else if (path_string.includes("add_patient_to_trial")) {
      setActiveTile("add_patient_to_trial");
    } else if (path_string.includes("audit")) {
      setActiveTile("audit");
    } else if (path_string.includes("admin")) {
      setActiveTile("admin");
    } else if (path_string.includes("drug_management")) {
      setActiveTile("drug_management");
    } else if (path_string.includes("reports")) {
      setActiveTile("reports");
    } else if (path_string.includes("query")) {
      setActiveTile("query");
    } else if (path_string.includes("ae")) {
      setActiveTile("ae");
    } else if (path_string.includes("role_config")) {
      setActiveTile("role_config");
    } else {
      setActiveTile("");
    }
  }, [path_string]);

  const determineActiveTile = () => {};

  const route_url = (route) => {
    router.navigate(route);
    props.close_module_bar();
  };

  const get_tile_style = (key) => {
    if (key === activeTile) {
      return styles.sidebar_button_active;
    } else {
      return styles.sidebar_button;
    }
  };

  const get_tile_inner_style = (key) => {
    if (key === activeTile) {
      return { color: "white", fontSize: "34px", lineHeight: "30px" };
    } else {
      return { color: "#bbb", fontSize: "34px", lineHeight: "30px" };
    }
  };

  const options = [
    { id: 1, name: "ACT Global", value: 1 },
    { id: 2, name: "Escape Core", value: 2 },
  ];

  const activated = !(
    user_data.studies &&
    (!user_data.studies.find((s) => s.id === study_id) ||
      (user_data.studies.find((s) => s.id === study_id) &&
        !user_data.studies.find((s) => s.id === study_id).activated))
  );
  return (
    <div
      id={styles.leftFlyoutMenu}
      className={showing_module_bar ? styles.show : styles.hide}
      ref={ref}
    >
      {user_data.studies?.length > 1 ? (
        <div className={styles.study_select_wrapper}>
          <div
            style={{ alignSelf: "flex-start", marginLeft: 26, marginBottom: 4 }}
          >
            Study
          </div>
          <Select
            value={study_id}
            sx={{
              fontSize: 14,
              minWidth: 200,
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            onChange={(e) => {
              let _study_id = e.target.value;
              post("/active_study", { value: _study_id }).then(() => {
                dispatch(setActiveStudyId(_study_id));
                setTimeout(() => (window.location = "/"), 200);
              });
            }}
          >
            {user_data?.studies?.map((o) => (
              <MenuItem key={o.id} value={o.id}>
                {o.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : null}

      {true || user_data.roles?.length > 1 ? (
        <div className={styles.study_select_wrapper}>
          <div
            style={{ alignSelf: "flex-start", marginLeft: 26, marginBottom: 4 }}
          >
            Role
          </div>
          <Select
            value={role_id}
            sx={{
              fontSize: 14,
              minWidth: 200,
              height: 30,
              padding: 0,
              alignSelf: "center",
            }}
            onChange={(e) => {
              let _role_id = e.target.value;
              post("/active_role", { value: _role_id }).then(() => {
                dispatch(setActiveRoleId(_role_id));
                setTimeout(() => (window.location = "/"), 200);
              });
            }}
          >
            {user_data?.roles?.length &&
              user_data.roles.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.name}
                </MenuItem>
              ))}
          </Select>
        </div>
      ) : null}

      {!activated ? (
        <div className={styles.not_activated}>
          Your user is not yet activated for this study.
        </div>
      ) : null}

      {activated && permissions.includes("randomization_access") ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("add_patient_to_trial")}
            onClick={() => route_url("/select_domain")}
          >
            <AddIcon sx={get_tile_inner_style("add_patient_to_trial")} />
          </div>

          <div className={styles.module_button_text}>Add Subject to Trial</div>
        </div>
      ) : null}

      {activated && permissions.includes("subjects_sidebar_icon") ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("subjects")}
            onClick={() => route_url("/subjects")}
          >
            <ViewListIcon sx={get_tile_inner_style("subjects")} />
          </div>
          <div className={styles.module_button_text}>Subjects</div>
        </div>
      ) : null}

      {/*
        <div className={styles.button_wrapper}>
          <div
            className={styles.sidebar_button}
            onClick={() => this.route_url("/edc")}
          >
            <DocumentScannerIcon
              sx={{ color: "#bbb", fontSize: "34px", lineHeight: "30px" }}
            />
          </div>
          <div className={styles.module_button_text}>EDC</div>
        </div>
        */}

      {/*}
        <div className={styles.button_wrapper}>
          <div className={styles.sidebar_button}>
            <SchemaIcon
              sx={{ color: "#bbb", fontSize: "34px", lineHeight: "30px" }}
            />
          </div>

          <div className={styles.module_button_text}>Workflow</div>
        </div>
        */}

      {activated && permissions.includes("drugs_sidebar_icon") ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("drug_management")}
            onClick={() => route_url("/drug_management")}
          >
            <MedicationIcon sx={get_tile_inner_style("drug_management")} />
          </div>

          <div className={styles.module_button_text}>Drug Management</div>
        </div>
      ) : null}

      {activated && permissions.includes("queries_sidebar_icon") ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("query")}
            onClick={() => route_url("/query")}
          >
            <QuestionAnswerIcon sx={get_tile_inner_style("query")} />
          </div>
          <div className={styles.module_button_text}>Queries</div>
        </div>
      ) : null}

      {activated && permissions.includes("ae_sidebar_icon") ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("ae")}
            onClick={() => route_url("/ae")}
          >
            <PriorityHighIcon sx={get_tile_inner_style("ae")} />
          </div>

          <div className={styles.module_button_text}>SAE/AE</div>
        </div>
      ) : null}

      {activated && permissions.includes("audit_sidebar_icon") ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("audit")}
            onClick={() => route_url("/audit")}
          >
            <FindInPageIcon sx={get_tile_inner_style("audit")} />
          </div>
          <div className={styles.module_button_text}>Audit</div>
        </div>
      ) : null}

      {activated && permissions.includes("reports_sidebar_icon") ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("reports")}
            onClick={() => route_url("/reports")}
          >
            <FindInPageIcon sx={get_tile_inner_style("reports")} />
          </div>
          <div className={styles.module_button_text}>Reports</div>
        </div>
      ) : null}

      {activated &&
      arrayHaveCommonItems(permissions, ["user_admin", "site_admin"]) ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("admin")}
            onClick={() => route_url("/admin")}
          >
            <SettingsOutlinedIcon sx={get_tile_inner_style("admin")} />
          </div>
          <div className={styles.module_button_text}>Admin</div>
        </div>
      ) : null}

      {activated && arrayHaveCommonItems(permissions, ["role_config"]) ? (
        <div className={styles.button_wrapper}>
          <div
            className={get_tile_style("role_config")}
            onClick={() => route_url("/role_config")}
          >
            <SettingsOutlinedIcon sx={get_tile_inner_style("role_config")} />
          </div>
          <div className={styles.module_button_text}>Role Config</div>
        </div>
      ) : null}
      {/*
        <div className={styles.button_wrapper}>
          <div className={styles.sidebar_button}>
            <TuneIcon
              sx={{ color: "#bbb", fontSize: "34px", lineHeight: "30px" }}
            />
          </div>

          <div className={styles.module_button_text}>Configuration</div>
        </div>
        */}
      <div className={styles.logout} onClick={logout}>
        Logout
      </div>
      <div className={styles.version}>{`v${version}`}</div>
    </div>
  );
}
