import React, { useRef, useEffect, useState } from "react";
import { get } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./ae.module.css";

import AETable from "./components/ae_table";

export default function Audit() {
  return (
    <div className={styles.flex}>
      <div className={styles.header}>
        <div>
          <h3>Adverse Events / Serious Adverse Events</h3>
        </div>
        <div className={styles.search_wrapper}></div>
      </div>
      <AETable />
    </div>
  );
}
