import React, { useState, useEffect } from "react";
import styles from "./randomize.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import DropDown from "ui/crfs/drop_down";
import Text from "ui/crfs/text";
import ButtonToModal from "ui/crfs/button_to_modal";
import EmailConsent from "./components/email_consent";
import PaperConsentCapture from "./components/paper_consent_capture";
import ConsentContent from "../electronic_consent_form/consent_content";
import CheckIcon from "@mui/icons-material/Check";
import { useParams } from "react-router-dom";
import { get, post } from "util/requests";
import moment from "moment";

import router from "routes";

const all_domain_info = {
  act42: { name: "ACT-42" },
  actwhen: { name: "Intravenous Thrombolysis" },
  bp: { name: "Blood Pressure" },
};

export default function Consent() {
  const { domain_id, existing_subject_id } = useParams();
  const [is_loading, setLoading] = useState(false);
  const [blockContinue, setBlockContinue] = useState(false);
  const [data, setData] = useState({ domain_code: domain_id });
  const [siteOptions, setSiteOptions] = useState(null);
  const handleFieldChange = () => {};
  const handleRandomize = () => {
    let now = new Date();
    let _data = Object.assign(data, {
      date: moment(now).format("YYYY-MM-DD"),
      time: moment(now).format("HH:mm"),
      consent_type: "patient",
      existing_subject_id: existing_subject_id,
    });
    post("/consent_record", _data).then((ret) => {
      let { id } = ret.data;
      router.navigate(`randomize_subject/${id}`);
    });
  };
  const batchValueChange = (changeObj) => {
    let newData = { ...data };
    Object.assign(newData, changeObj);
    setData(newData);
  };

  useEffect(() => {
    post("/audit", { action: "view_start_consent" });

    get(`/consent_options/${domain_id}`).then((ret) => {
      if (ret.data && ret.data.site_options && ret.data.site_options.length) {
        let options = ret.data.site_options.map((s) => ({
          label: `${s.name} (${s.code})`,
          value: s.code,
        }));
        setSiteOptions(options);
        batchValueChange({
          "disable-region": true,
          site_code: options[0].value,
        });
      }
    });
  }, []);

  const changeValue = (key, value) => {
    let newData = { ...data };
    newData[key] = value;
    setData(newData);
  };
  const getValue = (key) => {
    return data[key] || "";
  };
  // const options = [{ label: "999-Central", value: "999" }];

  const consent_type_options = ["Patient", "Surrogate"].map((r) => ({
    label: r,
    value: r.toLowerCase().replaceAll(" ", "-"),
  }));
  const consent_method_options = ["Paper"].map((r) => ({
    label: r,
    value: r.toLowerCase().replaceAll(" ", "-"),
  }));

  const show_continue_button =
    !blockContinue &&
    (getValue("consent_method") === "paper" ||
      (getValue("consent_method") === "email" && getValue("email_sent")) ||
      (getValue("consent_method") === "electronic" &&
        getValue("electronic_consent_captured")) ||
      getValue("consent_type") === "deferral");

  const show_consent_method_checkmark =
    !!getValue("consent_method") &&
    !(getValue("consent_method") === "email" && !getValue("email_sent")) &&
    !(
      getValue("consent_method") === "electronic" &&
      !getValue("electronic_consent_captured")
    );

  const domain_info = all_domain_info[domain_id];

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {(domain_info && domain_info.name) || ""} Consent
      </div>
      <DropDown
        disabled={
          !!getValue("disable-region") &&
          (!siteOptions || siteOptions.length <= 1)
        }
        title={"Site"}
        options={siteOptions}
        uval={getValue("site_code")}
        onUpdate={(value) => changeValue("site_code", value)}
        show_checkmark={!!getValue("site_code")}
      />
      {/*}
      <Text
        title={"Domain"}
        uval={"ACT-42"}
        onUpdate={() => {}}
        show_checkmark
      />*/}
      <DropDown
        disabled={!getValue("site_code")}
        title={"Consent Type"}
        options={consent_type_options}
        uval={getValue("consent_type") || ""}
        onUpdate={(value) => changeValue("consent_type", value)}
        show_checkmark={!!getValue("consent_type")}
      />
      {getValue("consent_type") !== "deferral" ? (
        <DropDown
          disabled={!getValue("consent_type")}
          title={"Media of Consent"}
          options={consent_method_options}
          uval={getValue("consent_method") || ""}
          onUpdate={(value) => changeValue("consent_method", value)}
          show_checkmark={show_consent_method_checkmark}
        />
      ) : null}
      {getValue("consent_method") === "electronic" ? (
        !getValue("electronic_consent_captured") ? (
          <ButtonToModal
            disabled={!getValue("consent_method")}
            title={"Consent Form"}
            content={
              <ConsentContent
                onComplete={() => {
                  changeValue("electronic_consent_captured", true);
                }}
              />
            }
          />
        ) : (
          <div className={styles.econsent_success_wrapper}>
            <div>Electronic Consent Captured</div>
            <div className={styles.check_mark}>
              <CheckIcon sx={{ color: "green" }} />
            </div>
          </div>
        )
      ) : null}
      {getValue("consent_method") === "email" ? (
        <EmailConsent
          onComplete={() => {
            changeValue("email_sent", true);
          }}
        />
      ) : null}
      {false && getValue("consent_method") === "paper" ? (
        <PaperConsentCapture
          setBlockContinue={setBlockContinue}
          setParentFileID={(file_id) => changeValue("file_id", file_id)}
        />
      ) : null}

      <div className={styles.button_wrapper}>
        <LoadingButton
          variant="contained"
          loading={is_loading}
          onClick={handleRandomize}
          disabled={!show_continue_button}
        >
          Next
        </LoadingButton>
      </div>
    </div>
  );
}
