import React, { useState, useEffect } from "react";
import styles from "../user_role_modal/user_role_modal.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, Checkbox } from "@mui/material";

export default function UserSiteModal(props) {
  const {
    activeSites,
    activeRegions,
    siteList,
    siteSelectIsOpen,
    setSiteSelectIsOpen,
    updateParent,
    user,
    selectedRoleId,
    regionList,
    roleName,
    currentLoggedInUserHasRegionAccess,
  } = props;

  const [accessByRegion, setAccessByRegion] = useState(
    props.accessByRegion || false,
  );

  useEffect(() => {
    setSites(activeSites.map((s) => s.id));
    setRegions(activeRegions.map((r) => r.id));
    setAccessByRegion(props.accessByRegion || false);
  }, [selectedRoleId]);

  const [sites, setSites] = useState(
    (activeSites && activeSites.map((r) => r.id)) || [],
  );
  const [regions, setRegions] = useState(
    (activeRegions && activeRegions.map((r) => r.id)) || [],
  );
  console.log("props.accessByRegion", props.accessByRegion);

  const updateSites = (site_id, checked) => {
    let updated_sites = [...sites];
    if (checked) {
      updated_sites.push(site_id);
    } else {
      updated_sites = updated_sites.filter((id) => id !== site_id);
    }
    setSites(updated_sites);
  };

  const updateRegions = (region_id, checked) => {
    let updated_regions = [...regions];
    if (checked) {
      updated_regions.push(region_id);
    } else {
      updated_regions = updated_regions.filter((id) => id !== region_id);
    }
    setRegions(updated_regions);
  };

  return (
    <Dialog open={siteSelectIsOpen} onClose={() => setSiteSelectIsOpen(false)}>
      <div className={styles.dialog_wrapper}>
        <div className={styles.roles_dialog_title}>
          <strong>
            Site Access: {user.first_name} {user.last_name}
          </strong>
        </div>
        <div className={styles.roles_dialog_title}>
          <strong>For role: {roleName}</strong>
        </div>
        {currentLoggedInUserHasRegionAccess ? (
          <div
            className={styles.role_row}
            onClick={() => setAccessByRegion(!accessByRegion)}
          >
            <Checkbox
              key={"access_by_region"}
              checked={accessByRegion}
              className={styles.checkbox}
            />
            Configure user's access by region
          </div>
        ) : null}
        {!accessByRegion ? (
          <div className={styles.role_wrapper}>
            {siteList.map((site) => (
              <div
                className={styles.role_row}
                onClick={() => updateSites(site.id, !sites.includes(site.id))}
              >
                <Checkbox
                  key={site.id}
                  checked={sites.includes(site.id)}
                  className={styles.checkbox}
                />
                <div>
                  {site.name} {` (${site.code})`}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.role_wrapper}>
            {regionList.map((region) => (
              <div
                className={styles.role_row}
                onClick={() =>
                  updateRegions(region.id, !regions.includes(region.id))
                }
              >
                <Checkbox
                  key={region.id}
                  checked={regions.includes(region.id)}
                  className={styles.checkbox}
                />
                <div>
                  {region.name} {` (${region.code})`}
                </div>
              </div>
            ))}
          </div>
        )}
        {props.accessByRegion && !currentLoggedInUserHasRegionAccess ? (
          <div>
            Your active role does not have appropriate regional access to manage
            by region.
          </div>
        ) : null}
        <div className={styles.dialog_button_wrapper}>
          {(props.accessByRegion && currentLoggedInUserHasRegionAccess) ||
          !props.accessByRegion ? (
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={() => {
                console.log("update parent", sites);
                updateParent({ accessByRegion, sites, regions });

                setSiteSelectIsOpen(false);
              }}
            >
              UPDATE
            </LoadingButton>
          ) : null}
          <LoadingButton
            variant="standard"
            loading={false}
            onClick={() => {
              setSiteSelectIsOpen(false);
            }}
          >
            CANCEL
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
