import { useRouteError } from "react-router-dom";
import { post } from "util/requests";
import { useLocation } from "react-router-dom";

export default function ErrorBoundary() {
  const location = useLocation();
  let error = useRouteError();
  post("/error_route", { error: error.toString(), path: location.pathname });
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <div>
      An error occurred and we have been notified. There is nothing else you
      need to do here.
    </div>
  );
}
