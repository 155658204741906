import { Component } from "react";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./index.css";
import Sidebar from "./components/sidebar";
import Routes from "./routes";
import Header from "ui/header";
import ModuleBar from "ui/module_bar";
import styles from "./base_route.module.css";

export default class BaseRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      showing_module_bar: false,
    };
    this.waffle_ref = null;
  }
  close_module_bar = () => {
    this.setState({
      showing_module_bar: false,
    });
  };
  toggle_module_bar = () => {
    this.setState({
      showing_module_bar: !this.state.showing_module_bar,
    });
  };

  set_waffle_ref = (waffle_ref) => {
    this.waffle_ref = waffle_ref;
  };

  render() {
    const { is_loading, showing_module_bar } = this.state;
    return (
      <div className={styles.full_page}>
        <Header
          toggleModuleBar={this.toggle_module_bar}
          setWaffleRef={this.set_waffle_ref}
        />
        <div className="wrapper">
          <Routes />
        </div>
        <ModuleBar
          showing_module_bar={showing_module_bar}
          close_module_bar={this.close_module_bar}
          waffle_ref={this.waffle_ref}
        />
      </div>
    );
  }
}
