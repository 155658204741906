import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { get, post } from "util/requests";
import styles from "./edc.module.css";
import EDCSidebar from "./components/edc_sidebar";
import Crf from "./components/crf";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import { MenuOpenIcon } from "ui/icons";

export default function EDC() {
  const {
    subject_id,
    visit_index,
    crf_id,
    site_id,
    study_id,
    form_type,
    multi_entry_crf_id,
  } = useParams();
  const [crfList, setCrfList] = useState([]);
  const [globalForms, setGlobalForms] = useState([]);
  const [showingSidebar, setShowingSidebar] = useState(true);
  const location = useLocation();

  const fetchSidebarData = () => {
    //this gets the sidebar crf content and the log form sidebars
    get(`/edc_crfs_for_visit/${study_id}/${visit_index}/${subject_id}`).then(
      (ret) => {
        setCrfList(ret.data.visit_crfs);
        setGlobalForms(ret.data.global_forms);
      },
    );
  };

  useEffect(() => {
    post("/footprint", {
      path: location.pathname,
      action: "pageload",
      name: "edc_base",
      subject_id,
      study_id,
      site_id,
    });
  }, [location.pathname]);

  useEffect(() => {
    fetchSidebarData();
  }, [visit_index]);

  const updateCrfQueryStatus = (_crf_id, status) => {
    let crf_list = cloneDeep(crfList);

    let crf = crf_list.find((c) => c.id === _crf_id);
    if (crf) {
      crf.query_status = status;
      setCrfList(crf_list);
    }
    //search in global forms
    else if (!crf) {
      crf = globalForms
        .reduce((accum, obj) => {
          obj.entries.forEach((crf) => accum.push(crf));
          return accum;
        }, [])
        .find((c) => c.id === _crf_id);
      if (crf) {
        crf.query_status = status;
        setGlobalForms(globalForms);
      }
    } else if (!crf && form_type === "multi_entry") {
      post("multi_entry_crf", {
        multi_entry_crf_id,
        crf_id,
        subject_id,
        visit_index,
        study_id,
        pathname: location.pathname,
      }).then((ret) => {
        let crf = ret.data;
        crf.query_status = status;
        let formsIndex = globalForms.findIndex((gf) => gf.id === crf_id);
        globalForms[formsIndex].entries.push(crf);
        setGlobalForms(globalForms);
      });
    }
  };

  const updateCrfStatus = (_crf_id, status, form_type, multi_entry_crf_id) => {
    console.log(
      "updateCrfStatus",
      _crf_id,
      status,
      form_type,
      multi_entry_crf_id,
    );
    return new Promise((resolve) => {
      let crf_list = cloneDeep(crfList);
      let global_forms_list = cloneDeep(globalForms);

      let crf = crf_list.find((c) => c.id === _crf_id);

      //search in global forms
      if (form_type === "multi_entry") {
        crf = global_forms_list.find((g) => g.id === _crf_id);
        if (crf) {
          //we do not have the appropriate statuses resolved for the multi-entry crfs, so we respond will null to keep the qc status local to the crf page only
          if (
            !global_forms_list
              .reduce((accum, obj) => {
                obj.entries.forEach((crf) => accum.push(crf));
                return accum;
              }, [])
              .find((c) => c.id === multi_entry_crf_id)
          ) {
            crf.total = crf.total += 1;
            let gf = global_forms_list.find((gf) => gf.id === _crf_id);
            gf.entries.push({ id: multi_entry_crf_id });
            setGlobalForms(global_forms_list);
          }

          resolve(null);
          return;
        }
      }

      if (status === "data" && crf && crf.status !== "data_complete") {
        crf.status = "data";
        setCrfList(crf_list);
        resolve("data");
      } else if (crf && status === "data_complete") {
        crf.status = "data_complete";
        setCrfList(crf_list);
        resolve("data_complete");
      } else if (crf && status === "remove_data_complete") {
        crf.status = "data";
        setCrfList(crf_list);
        resolve("data");
      } else if (status === "data" && !crf) {
        resolve("data");
      }
    });
  };

  const hideSidebar = (state) => {
    setShowingSidebar(!state);
  };

  return (
    <div className={styles.wrapper}>
      {showingSidebar ? (
        <EDCSidebar
          visit_index={visit_index}
          crf_id={crf_id}
          subject_id={subject_id}
          crfList={crfList}
          globalFormsList={globalForms}
          hideSidebar={hideSidebar}
        />
      ) : (
        <div>
          <MenuOpenIcon onClick={() => hideSidebar(false)} />
        </div>
      )}
      {form_type === "crf" ? (
        <Crf
          form_type={"crf"}
          key="crf"
          crf_id={crf_id}
          subject_id={subject_id}
          updateCrfQueryStatus={updateCrfQueryStatus}
          updateCrfStatus={updateCrfStatus}
          visit_index={visit_index}
          study_id={study_id}
          site_id={site_id}
          fetchSidebarData={fetchSidebarData}
        />
      ) : null}
      {form_type === "multi_entry" ? (
        <Crf
          form_type={"multi_entry"}
          multi_entry_crf_id={multi_entry_crf_id}
          key="multi_entry"
          crf_id={crf_id}
          subject_id={subject_id}
          updateCrfQueryStatus={updateCrfQueryStatus}
          updateCrfStatus={updateCrfStatus}
          visit_index={visit_index}
          study_id={study_id}
          site_id={site_id}
          fetchSidebarData={fetchSidebarData}
        />
      ) : null}
    </div>
  );
}
