import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import router from "routes";
import { get } from "util/requests";
import colors from "colors";
import moment from "moment";
import { ae_columns } from "../../edc/components/crf/log_form_table";

let columns = [...ae_columns];
columns.unshift({
  field: "subject_id",
  headerName: "Subject ID",
  width: 160,
  align: "center",
  headerAlign: "center",
});

export default function AETable(props) {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    get("/ae").then((ret) => setEvents(ret.data));
  }, []);

  return (
    <DataGrid
      rows={events}
      columns={columns}
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount
      pageSizeOptions={[10]}
      columnHeaderHeight={34}
      rowHeight={34}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      sx={{
        boxShadow: 0,
        border: 1,
        borderColor: "#f0f0f0",
        "& .MuiDataGrid-cell:hover": {
          color: "#8349fd",
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnHeaders": {
          // backgroundColor: "#f7f3fe",
          backgroundColor: colors.table_header_background,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: "#333",
        },
      }}
      onRowClick={(data) => {
        // console.log("row", data.row);
        router.navigate(data.row.path);
      }}
    />
  );
}
